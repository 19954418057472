import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import HeaderAdvancedFunctionality from '../../components/shared/landingPages/headerAdvancedFunctionality'
import WhyTellowCompleetFunction from '../../components/shared/landingPages/whytellowcompleet'
import Benefits from '../../components/shared/landingPages/benefits'
import PriceBlock from '../../components/shared/landingPages/priceBlock'
import header from '../../images/boekhoudinglp/headerImage.png'
import saveTime from '../../images/boekhoudinglp/saveTime.png'
import bookkeeper from '../../images/boekhoudinglp/bookkeeper.png'
import benefit from '../../images/boekhoudinglp/benefitsCompleet.png'
import whytellow from '../../images/whytellowcompleet/whytellowcompleet.png'
import XIcon from '../../components/UI/Icons/xIcon'
import QuestionIcon from '../../components/UI/Icons/questionIcon'
import TickIcon from '../../components/UI/Icons/tickIcon'
import { Title } from '../../components/shared/landingPages/title'
import { Accordeon } from '../../components/UI'
import { support } from '../../config'
import { CAMPAIGN } from './compleet'

const headerData = {
    title: `More than just a bookkeeper`,
    text: `Tellow Complete is your own personal bookkeeper with all the advantages of the user-friendly software of Tellow.`,
    headerImage: header,
    textCTA: `Start immediately`,
    campaign: CAMPAIGN,
}

const headerAdvancedFunctionalityData = {
    title: `In short`,
    subtitlePartOne: `Easily keep your records in your online account. Your personal bookkeeper checks them, prepares your quarterly VAT return, and the income tax return at the end of the year, also for your partner. Your bookkeeper is available throughout the year for support and all your questions via phone, email and chat. You also have access to the complete Tellow software, including sending quotes and invoices, scanning receipts, sending payment links and much more.`,
    titleImage: bookkeeper,
    firstStepImage: saveTime,
}

const benefitsData = {
    points: [
        {
            title: 'Your own bookkeeper',
            body: `Based on your type of sole proprietorship, you will be matched with one of Tellow's bookkeepers; we call this 'smart matching'. This way we guarantee the best bookkeeper for you. You get the phone number and email address so you can always contact them.`,
            links: `Read more about your bookkeeper`,
            url: `#FAQ`,
        },
        {
            title: 'Personal touch',
            body: 'Should I buy a laptop? How does private and business driving work? To deduct or not to deduct energy bills? You can ask all your questions to your bookkeeper. If you have a specific question, all our bookkeepers are in contact with each other and share their knowledge and experience.',
            links: `Schedule a free consultation`,
            url: `https://calendly.com/tellow-compleet/compleet-informatie`,
        },
        {
            title: 'Preventing errors and saving money',
            body: "As a freelancer, you want to make sure your returns are completed properly, savings are applied where possible and errors are avoided. Your personal bookkeeper will make sure you don't have to worry and you don't miss out on any savings.",
            links: `Get Tellow Complete`,
            url: `https://app.tellow.nl/registreer?lang=en&origin=${CAMPAIGN}`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const whyTellowCompleetData = {
    features: [
        [
            {
                title: <li>VAT calculations</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },
            {
                title: <li>Submit VAT return</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },
            {
                title: <li>Voorbereiding inkomstenbelasting (IB)</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },
            {
                title: <li>Submitting an income tax return (IB)</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },

            {
                title: <li>Tax partner tax return for free</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <QuestionIcon />,
            },
        ],
        [
            {
                title: <li>Unlimited calls to your accountant for questions</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <QuestionIcon />,
            },

            {
                title: <li>Matching to the accountant that suits you best</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Submit and store administration</li>,
                IconleftColumn: <XIcon />,
                IconrightColumn: <TickIcon />,
            },
        ],
        [
            {
                title: <li>Prepare and send invoices</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Hour registration and kilometer registration</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Offertes opmaken en versturen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li> Send payment reminders</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Send payment request for direct payment</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Real-time insight into your finances</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Business loans</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Invoice financing</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Automatically load bank transactions</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Native iOS and Android apps</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Web app for use on computer</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Personal advice</li>,
                IconleftColumn: <QuestionIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Available by email, chat and telephone also in the evening</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Informative webinars</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
    ],
    maintitle: `Why Tellow Complete?`,
    titleLeftColumn: `Tellow Compleet`,
    titleRightCoulmn: `Traditional Bookkeeper`,
    title: `Difference with a traditional accountant?`,
    text: `Tellow Complete is much more than just a bookkeeper. You can always have personal contact (without extra costs) and make unlimited use of Tellow's complete software. View all the differences in the comparator.`,
    whyTellowImage: whytellow,
    textCTA: `Compare`,
}

const priceBlockData = {
    options: [
        {
            price: `Tellow to the max`,
            title: `The best accounting software in the market`,
            features: [
                'Create invoices and quotes in a snap',
                'No more saving receipts, just scan and go',
                'Handy time and mileage registration',
                'Use via apps on all cell phones',
            ],
        },
        {
            price: 'Personal accountant',
            title: 'Your bookkeeper, who provides security',
            features: [
                'Personal accountant who takes care of everything',
                'Checks on accounting, returns and savings',
                'Peace of mind, for both business and private use',
                'Premium support and direct line for advice',
            ],
        },
    ],
    textCTA: 'Start with Complete',
    information: 'Do you have any questions? Call our advisors directly.',
    phone: support.phoneNumber,
}

const FAQ = [
    {
        question: 'What does it cost to have income tax done?',
        answer: `
            Are you taking Tellow Complete? Then our accountants will do your income tax every year at no extra cost.
            <br/><br/>
            This means the following:
            <ol>
                <li>Our bookkeeper will do your income tax return.</li>
                <li>Includes profit from business.</li>
                <li>Includes housing (with a new home, there may be additional costs).</li>
                <li>Includes possible earned income.</li>
                <li>Include all deductions: medical expenses, educational expenses, gifts, etc.</li>
                <li>Includes box 3 based on pre-filled data.</li>
                <li>Includes company car or private car with business use.</li>
                <li>Possibility to request an extension.</li>
            </ol>

            Do you have exceptions to the above? No problem. Tellow can take everything off your hands. We use a fixed price list for this. Read more about this in the article below: What does Tellow do with exceptions in my income tax return?
        `,
    },
    {
        question: 'Does Tellow do my income taxes every year?',
        answer: `
        Yes! With Tellow Complete, our accountants will file your income tax return every year for as long as your subscription is active. Without any additional costs.
        `,
    },
    {
        question: 'When do I need to do income tax?',
        answer: `
        You can file your income tax return every year starting March 1, and it must be filed with the Belastingdienst by May 1.
                    <br/><br/>
                    Have you received a tax return letter and are you late in filing your tax return? Then you risk a fine. We therefore recommend that you always file your tax return correctly and on time.
            <br/><br/>
            We can help with requesting a deferral until September 1, we offer this service free of charge.
        `,
    },
    {
        question: `How does income tax return work for freelancers?`,
        answer: `
        As a self-employed person you pay no tax up to a profit of €30,439. However, this does not mean that you do not have to file a tax return if your profit falls below this amount; you must always file a tax return.
            <br/><br/>
            As a self-employed person, you can also make use of deductions such as the self-employed tax deduction, starter's tax deduction, old-age reserve, SME profit exemption and various tax credits.
            <br/><br/>
            With Tellow Complete our bookkeepers check your administration and know exactly which discounts you are entitled to. This will save you a lot of money.
        `,
    },
    {
        question: "Does Tellow also do my tax partner's return?",
        answer: `
        Tellow does your tax partner's return for free if he or she is employed.
            <br/><br/>
            If your tax partner is also an entrepreneur or self-employed person then he or she will have to take out a Tellow Complete subscription themselves or there will be a one-time additional charge.
        `,
    },
    {
        question: 'I have assets in box 3, does this count as an exception?',
        answer: `
        Pre-filled assets within Box 3 are completely taken care of by the accountant.
            <br/><br/>
            About the assets outside the pre-filled data, there may be additional charges by the accountant. This varies depending on the situation and composition of the assets.
            <br/><br/>
            See our price list for all exceptions and costs.
        `,
    },
    {
        question: 'I bought a house this year, does this count as an exception?',
        answer: `
        If you bought a house in the fiscal year this is considered by our accountants to be an exception. There will be an additional charge. These costs are a fixed amount of €50 euro.
        `,
    },
    {
        question: 'What exceptions apply to the income tax return?',
        answer: `
        If you have exceptions to a normal income tax return, we have a fixed price list for additional fees. These are much lower than traditional accountants.
            <br/><br/>
            See below for exceptions and additional fees:
            <ol>
                <li>Booking of adminstration €150.</li>
                <li>VAT supplementation €50.</li>
                <li>Box 3 return outside of pre-filled data €100.</li>
                <li>M/C bill (abroad) €300.</li>
                <li>House bought and/or sold €50.</li>
                <li>Family loan €50.</li>
                <li>Annuity €50.</li>
            </ol>

            Do you have exceptions to the above? No problem. Tellow can take everything off your hands. We use a fixed price list for this. Read more about this in the article below: What does Tellow do with exceptions in my income tax return?
        `,
    },
]

const Compleet = () => (
    <Layout registrationButtonCampaignId={CAMPAIGN}>
        <Meta
            keywords={['accountant', 'zzp', 'freelancer', 'entrepreneur', 'sole proprietor', 'administration', 'bookkeeping', 'accounting package']}
            path="/en/plan/complete"
            title="Bookkeeper | Tellow.com"
            description="Tellow Complete gives you complete certainty about your administration. No more complicated and time-consuming tax returns! Your own accountant will take care of everything for you. Get unlimited support and ask your questions anytime, anywhere with Tellow Complete. Sign up now"
        />

        {/* First Component */}
        <Header data={headerData} />

        {/* Second Component */}
        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HeaderAdvancedFunctionality data={headerAdvancedFunctionalityData} bookkeepers />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Third Component */}
        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* whytellow component */}
        <RelativeContainer>
            <ComponentContainer>
                <WhyTellowCompleetFunction data={whyTellowCompleetData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* Fourth Component */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <PriceBlock data={priceBlockData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Fifth Component */}
        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <Title>Frequently Asked Questions</Title>
                {FAQ.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Compleet
